<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="beneficiarios"
          item-key="tipoBenId"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:[`item.tipoBenNoIncluirPadron`]="{ item }">
            <v-icon
              v-if="item.tipoBenNoIncluirPadron"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>

          <template v-slot:[`item.tipoBenEsDesempleo`]="{ item }">
            <v-icon
              v-if="item.tipoBenEsDesempleo"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>

          <template v-slot:[`item.cuilTitularEnCuitempleador`]="{ item }">
            <v-icon
              v-if="item.cuilTitularEnCuitempleador"
              small
              color="primary"
              class="d-flex justify-center"
            >
              {{ checkIcon }}
            </v-icon>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar tipo de beneficiario</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="deleteBenef(item)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar beneficiario</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="60%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditTiposBeneficiarios
        :tiposBenefObj="beneficiarios"
        :editBeneficiariosItem="editBeneficiariosItem"
        @closeAndReload="closeAndReload"
      ></EditTiposBeneficiarios>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditTiposBeneficiarios from "@/components/modules/afiliaciones/afiliados/configuracion/EditTiposBeneficiarios.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  components: {
    PageHeader,
    DeleteDialog,
    EditTiposBeneficiarios,
    GoBackBtn,
    Ayuda
  },
  name: "TiposBeneficiarios",
  data: () => ({
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
    searchIcon: enums.icons.SEARCH,
    search: "",
    checkIcon: enums.icons.CHECK_OUTLINE,
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    optionCode: enums.webSiteOptions.TIPOS_BENEFICIARIOS,
    showDeleteModal: false,
    idToDelete: null,
    routeToGo: "TablasAfiliados",
    title: enums.titles.BENEFICIARIOS,
    titleDelete: enums.titles.DELETE_BENEF,
    rules: rules,
    beneficiarios: [],
    editBeneficiariosItem: {},
    headers: [
      {
        text: "Nombre del beneficiario",
        value: "tipoBenNom",
        sortable: true
      },
      {
        text: "Código SSS",
        value: "tipoBenCod",
        sortable: false
      },
      {
        text: "Situación en revisión del beneficiario",
        value: "tipoBenSituRevi",
        sortable: false
      },
      {
        text: "Excluir del padrón mensual a la SSS",
        value: "tipoBenNoIncluirPadron",
        sortable: false
      },
      {
        text: "Tipo beneficiario desempleo",
        value: "tipoBenEsDesempleo",
        sortable: false
      },
      {
        text:
          "Forzar CUIL del Titular en campo CUIT en el padrón mensual a la SSS",
        value: "cuilTitularEnCuitempleador",
        sortable: false
      },
      {
        text: "Forzar el siguiente CUIT en el padrón mensual a la SSS",
        value: "forzarCuitExpSss",
        sortable: false
      },

      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
    openModalEdit: false
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.loadBeneficiarios();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getTiposBeneficiarios: "configAfiliaciones/getTiposBeneficiarios",
      deleteTipoBeneficiario: "configAfiliaciones/deleteTipoBeneficiario",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVO_TIPO_BENEF:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_TIPO_BENEF:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_TIPO_BENEF:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async loadBeneficiarios() {
      this.isLoading = true;
      const data = await this.getTiposBeneficiarios();
      this.beneficiarios = data;
      this.isLoading = false;
    },
    deleteBenef(item) {
      this.showDeleteModal = true;
      this.itemToDelete = item;
    },
    async confirmDelete() {
      const response = await this.deleteTipoBeneficiario({
        tipoBenId: this.itemToDelete.tipoBenId
      });
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadBeneficiarios();
      }
    },
    openModal(item) {
      this.openModalEdit = true;
      this.editBeneficiariosItem = item;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.loadBeneficiarios();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep label {
  margin-bottom: 0;
}
</style>
