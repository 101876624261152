<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="tipoBenNom"
                type="text"
                label="Nombre"
                dense
                outlined
                :rules="
                  rules.required.concat([
                  rules.requiredTrim(tipoBenNom),
                  rules.maxLength(tipoBenNom, 50)])
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="tipoBenCod"
                label="Código SSS"
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                :rules="rules.required.concat([rules.maxLength(tipoBenCod, 2)])"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="tipoBenSituRevi"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                label="Situación en revisión del beneficiario"
                dense
                outlined
                :rules="
                  tipoBenSituRevi
                    ? [
                        rules.maxLength(tipoBenSituRevi, 4),
                      ]
                    : []
                "
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-switch
                v-model="tipoBenNoIncluirPadron"
                item-text="value"
                item-value="id"
                label="Excluir del padrón mensual a la SSS"
                outlined
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-switch
                v-model="tipoBenEsDesempleo"
                item-text="value"
                item-value="id"
                label="Tipo beneficiario desempleo"
                outlined
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-switch
                v-model="cuilTitularEnCuitempleador"
                item-text="value"
                item-value="id"
                label="Forzar CUIL del Titular en campo CUIT en el padrón mensual a la SSS"
                outlined
              ></v-switch>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="forzarCuitExpSss"
                label="Forzar el siguiente CUIT en el padrón mensual a la SSS"
                outlined
                type="text"
                dense
                v-mask="'##-########-#'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :rules="validarCUIL(forzarCuitExpSss)"
                autocomplete="off"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
      <ConfirmDialog
        :text="textConfirmDialog"
        :openConfirm.sync="openConfirmDialog"
        @update:openConfirm="cancelar"
        @onConfirm="save(saveTipoBen)"
      />
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";
import validateCUIL from "@/utils/helpers/validateCUIL";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "EditTiposBeneficiarios",
  directives: { mask },
  components: { ConfirmDialog },

  props: {
    tiposBenefObj: {
      type: Array,
      required: true,
    },
    editBeneficiariosItem: {
      type: Object,
    },
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: enums.titles.EDITAR_TIPO_BENEF,
    newTitle: enums.titles.NUEVO_TIPO_BENEF,
    rules: rules,
    tipoBenNom: "",
    tipoBenNoIncluirPadron: false,
    tipoBenEsDesempleo: false,
    cuilTitularEnCuitempleador: false,
    forzarCuitExpSss: null,
    tipoBenSituRevi: null,
    tipoBenCod: null,
    openConfirmDialog: false,
    saveTipoBen: null,
    textConfirmDialog: "",
  }),
  created() {
    if (this.editBeneficiariosItem) {
      this.setBeneficiario(this.editBeneficiariosItem);
    } else {
      this.newBeneficiario();
    }
  },
  methods: {
    ...mapActions({
      saveTiposBeneficiarios: "configAfiliaciones/saveTiposBeneficiarios",
      setAlert: "user/setAlert"
    }),

    validarCUIL(cuil) {
      if (cuil == null || cuil == "") return [];
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        return ["El CUIT/CUIL ingresado es incorrecto."];
      }
    },

    async setBeneficiario(obj) {
      this.tipoBenId = obj.tipoBenId;
      this.tipoBenNom = obj.tipoBenNom;
      this.tipoBenCod = obj.tipoBenCod;
      this.tipoBenSituRevi = obj.tipoBenSituRevi;
      this.tipoBenNoIncluirPadron = obj.tipoBenNoIncluirPadron;
      this.tipoBenEsDesempleo = obj.tipoBenEsDesempleo;
      this.cuilTitularEnCuitempleador = obj.cuilTitularEnCuitempleador;
      this.forzarCuitExpSss = obj.forzarCuitExpSss;
    },
    async newBeneficiario() {
      this.formEditTitle = this.newTitle;
    },

    codigoRepeat() {
      let sameCodeSss;
      if (this.tipoBenCod != null) {
        sameCodeSss = this.tiposBenefObj.find(
          (x) => x.tipoBenCod == this.tipoBenCod
        );
      }
      if (sameCodeSss) {
        this.setAlert({
          type: "warning",
          message: "Existe otro registro con el mismo código SSS",
        });
      }
    },

    cancelar() {
      (this.saveTipoBen = null), (this.textConfirmDialog = "");
    },

    canSave() {
      this.saveTipoBen = {
        tipoBenNom: this.tipoBenNom,
        tipoBenCod: parseInt(this.tipoBenCod),
        tipoBenSituRevi: this.tipoBenSituRevi,
        tipoBenNoIncluirPadron: this.tipoBenNoIncluirPadron,
        tipoBenEsDesempleo: this.tipoBenEsDesempleo,
        cuilTitularEnCuitempleador: this.cuilTitularEnCuitempleador,
        forzarCuitExpSss: this.forzarCuitExpSss
          ? this.forzarCuitExpSss.replaceAll("-", "")
          : this.forzarCuitExpSss,
        tipoBenId: this.editBeneficiariosItem ? this.tipoBenId : null,
      };

      let sameCodeSss;
      if (this.tipoBenCod != null) {
        sameCodeSss = this.tiposBenefObj.find(
          (x) => x.tipoBenCod == this.tipoBenCod
        );
      }

      if (sameCodeSss) {
        this.textConfirmDialog = `El código SSS "${this.tipoBenCod}" ya se encuentra en "${sameCodeSss.tipoBenNom}". `;        
      }

      this.textConfirmDialog += "¿Desea confirmar los cambios?";
      if (this.textConfirmDialog === "¿Desea confirmar los cambios?") {
        this.save(this.saveTipoBen);
      } else {
        this.openConfirmDialog = true;
      }      
    },

    async save(data){
      const res = await this.saveTiposBeneficiarios(data);
      if (res.status === 200) {
        setTimeout(() => {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
        }, 1200);
        this.closeModal();
      }
    },

    closeModal() {
      this.$emit("closeAndReload");
    },
  },
};
</script>

<style scoped>
.repeat input {
  border-color: red;
}
.repeat label {
  color: red;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;

  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
</style>
